<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">素材管理</span>
		</div>
		<div class="material material-dialog mt-2 mb-2">
			<el-card shadow="never" body-style="padding: 0;">
				<div class="table-body">
					<el-tabs v-model="tabs" @tab-click="dialogOpened">
						<el-tab-pane v-for="(v,index) in tab_list" :key="index" :label="v.label" :name="v.name">
							<div class="d-flex align-items-center" style="margin-bottom: 12px;">
								<el-button size="small" type="primary" @click="showAddGroup(-1)">添加分组</el-button>
								<div style="margin-left:200px">全部</div>
								<div class="d-flex ml-auto align-items-center">
									<div class="search" style="margin-right: 12px">
										<el-input placeholder="请输入名称搜索" v-model="keyword" clearable
											@clear="dialogOpened" size="small" @keyup.enter.native="dialogOpened"
											class="input-with-select">
											<el-button @click="dialogOpened" slot="append" icon="el-icon-search">
											</el-button>
										</el-input>
									</div>
									<el-checkbox v-model="selectAll" @change="selectAllChange" label="全选" size="small"
										style="margin-right: 12px;margin-bottom: 0"></el-checkbox>
									</el-button>
									<el-button :loading="deleteLoading" @click="deleteItems" size="small"
										style="margin-right: 12px">删除
									</el-button>
									<el-dropdown v-loading="moveLoading" trigger="click" split-button
										size="medium" @command="moveItems">
										移动至
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item v-for="(item, index) in groupList" :command="index"
												:key="index">
												{{item.group_name}}
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>

							<div class="d-flex main-box">
								<div class="left">
									<el-menu class="group-menu" mode="vertical" v-loading="groupListLoading">
										<el-menu-item index="all" @click="switchGroup(-1)">
											<i class="el-icon-tickets"></i>
											<span>全部</span>
										</el-menu-item>
										<template v-for="(item, index) in groupItem">
											<el-menu-item :index="'' + index" @click="switchGroup(index)">
												<div class="d-flex justify-content-between">
													<div style="overflow: hidden;text-overflow: ellipsis">
														<i class="el-icon-tickets"></i>
														<span>{{item.group_name}}</span>
													</div>
													<div class="d-flex">
														<el-button type="text" @click.stop="showAddGroup(index)">编辑
														</el-button>
														<div style="color:#e3e3e3;margin:0 2px">|</div>
														<el-button type="text" @click.stop="deleteGroup(index,1)">删除
														</el-button>
													</div>
												</div>
											</el-menu-item>
										</template>
									</el-menu>
								</div>


								<div class="d-flex right" v-loading="loading" style="flex-direction: column;">
									<div class="material-list">
										<div class="material-item material-upload">
											<app-upload class="align-items-center d-flex justify-content-center"
												v-loading="uploading" :disabled="uploading" @start="handleStart"
												@success="handleSuccess" @complete="handleComplete" :multiple="true"
												:max="10" :accept="accept" style="width: 140px;height: 140px"
												:groupId="currentGroupId">
												<div v-if="uploading">{{uploadCompleteFilesNum}}/{{uploadFilesNum}}
												</div>
												<i v-else class="el-icon-upload"></i>
											</app-upload>
										</div>
										<template v-for="(item, index) in attachments">
											<el-tooltip class="item" effect="dark" :content="item.name" placement="top"
												:open-delay="1">
												<div :key="index"
													:class="'material-item'+(item.selected ?' selected':'')"
													@click="selectItem(item)">


													<img v-if="item.file_type == 'image'" class="material-img"
														:src="item.file_path" style="width: 140px;height: 140px;">


													<video controls="controls" v-if="item.file_type == 'video'"
														class="material-img"
														style="width: 140px;height: 140px;position: relative">
														<source :src="item.file_path">
													</video>


													<div class="d-flex align-items-center" style="margin-top:5px">
														<div class="material-name">{{item.name}}</div>
														<div style="margin:0 5px">|</div>
														<div>
															<el-button @click.stop="showPicModel(index)" type="text"
																style="padding:0">编辑</el-button>
														</div>
													</div>
												</div>
											</el-tooltip>
										</template>
									</div>
									<div style="padding: 5px;text-align: right;margin-top:auto;">
										<el-pagination v-if="pagination" background @size-change="handleLoadMore"
											@current-change="handleLoadMore" :current-page.sync="page"
											:page-size="pagination.per_page" layout="prev, pager, next, jumper"
											:total="pagination.total">
										</el-pagination>
									</div>
								</div>
							</div>
						</el-tab-pane>


						<el-dialog append-to-body title="分组管理" :visible.sync="addGroupVisible"
							:close-on-click-modal="false" width="30%">
							<el-form @submit.native.prevent label-width="90px" ref="groupForm" :model="groupForm"
								:rules="groupFormRule">
								<el-form-item label="分组名称" prop="group_name" style="margin-bottom: 22px;">
									<el-input v-model="groupForm.group_name" maxlength="8" show-word-limit></el-input>
								</el-form-item>
								<el-form-item style="text-align: right">
									<el-button type="primary" @click="groupFormSubmit('groupForm')"
										:loading="groupFormLoading" size="medium">保存
									</el-button>
								</el-form-item>
							</el-form>
						</el-dialog>


						<el-dialog append-to-body :title="tab_list[tabs=='image' ? 0: 1]['label'] + '名称修改'"
							:visible.sync="addPicVisible" :close-on-click-modal="false" width="30%">
							<el-form @submit.native.prevent label-width="90px" ref="picForm" :model="picForm"
								:rules="picFormRule">
								<el-form-item :label="tab_list[tabs=='image' ? 0: 1]['label'] + '名称'" prop="name"
									style="margin-bottom: 22px;">
									<el-input v-model="picForm.name"></el-input>
								</el-form-item>
								<el-form-item style="text-align: right">
									<el-button type="primary" @click="picFormSubmit('picForm')"
										:loading="picFormLoading" size="medium">保存
									</el-button>
								</el-form-item>
							</el-form>
						</el-dialog>
					</el-tabs>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tab_list: [{
						label: '图片',
						name: 'image'
					},
					{
						label: '视频',
						name: 'video'
					}
				],
				tabs: 'image',
				keyword: '',
				page: 1,
				selectAll: false,
				deleteLoading: false,
				moveLoading: false,
				groupList: [],
				groupItem: [],
				groupListLoading: false,
				currentGroupId: -1,
				loading: false,
				groupForm: {
					group_id: null,
					group_name: '',
				},
				addPicVisible: false,
				picFormLoading: false,
				picForm: {
					id: null,
					name: '',
				},
				picFormRule: {
					name: [{
						required: true,
						message: '请填写名称',
					}],
				},
				pagination: null,
				groupFormRule: {
					group_name: [{
						required: true,
						message: '请填写分组名称',
					}],
				},
				addGroupVisible: false,
				groupFormLoading: false,
				uploading: false,
				attachments: [],
				checkedAttachments: [],
				uploadParams: {},
				uploadCompleteFilesNum: 0,
				uploadFilesNum: 0,
			}
		},
		computed: {
			accept: {
				get() {
					if (this.tabs === 'image') {
						return 'image/*';
					}
					if (this.tabs === 'video') {
						return 'video/*';
					}
					return '*/*';
				},
			},
		},
		mounted() {
			this.dialogOpened();
		},
		methods: {
			showPicModel(index) {
				this.picForm = {
					file_id: this.attachments[index].file_id,
					name: this.attachments[index].name,
					edit_index: index,
				};
				this.addPicVisible = true;
			},
			picFormSubmit(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.picFormLoading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/upload.Library/rename',
								file_id: this.picForm.file_id,
							},
							data: this.picForm,
							method: 'post',
						}).then(e => {
							this.picFormLoading = false;
							if (e.data.code == 1) {
								this.$message.success(e.data.msg);
								this.addPicVisible = false;
								this.attachments[this.picForm.edit_index].name = this.picForm.name;
							} else {
								this.$message.error(e.data.msg);
							}
						}).catch(e => {
							self.picFormLoading = false;
						});
					}
				})
			},
			selectAllChange(value) {
				for (let i in this.attachments) {
					this.attachments[i].selected = value;
				}
			},
			selectItem(item) {
				item.selected = item.selected ? false : true;
			},
			dialogOpened() {
				this.page = 1;
				this.loading = true;
				this.loadGroups();
				this.loadList()
			},
			handleStart(files) {
				this.uploading = true;
				this.uploadFilesNum = files.length;
				this.uploadCompleteFilesNum = 0;
			},
			deleteItems() {
				const itemIds = [];
				for (let i in this.attachments) {
					if (this.attachments[i].selected) {
						itemIds.push(this.attachments[i].file_id);
					}
				}

				if (!itemIds.length) {
					this.$message.warning('请先选择需要处理的图片。');
					return;
				}

				let self = this;
				self.$confirm('是否确认将选中素材放入回收站中', '提示', {
					type: 'warning'
				}).then(() => {
					self.deleteLoading = true;
					self.axios({
						token: true,
						params: {
							s: 'store/upload.Library/deleteFiles',

						},
						data: {
							fileIds: itemIds
						},
						method: 'post',
					}).then(e => {
						self.deleteLoading = false;
						if (e.data.code === 1) {
							self.$message.success(e.data.msg);
							for (let i in itemIds) {
								for (let j in self.attachments) {
									if (self.attachments[j].file_id == itemIds[i]) {
										self.attachments.splice(j, 1);
										break;
									}
								}
							}
						} else {
							self.$message.error(e.data.msg);
						}
					}).catch(e => {
						self.deleteLoading = false;
					});
				}).catch(() => {});
			},

			moveItems(index) {
				const itemIds = [];
				for (let i in this.attachments) {
					if (this.attachments[i].selected) {
						itemIds.push(this.attachments[i].file_id);
					}
				}

				if (!itemIds.length) {
					this.$message.warning('请先选择需要移动的图片。');
					return;
				}
				let self = this;
				this.$confirm('确认移动所选的' + itemIds.length + '张图片？', '提示', {
					type: 'warning'
				}).then(() => {
					self.moveLoading = true;
					self.axios({
						token: true,
						params: {
							s: 'store/upload.Library/moveFiles',
							group_id: this.groupItem[index].group_id,
						},
						data: {
							fileIds: itemIds
						},
						method: 'post',
					}).then(e => {
						self.moveLoading = false;
						if (e.data.code === 1) {
							self.$message.success(e.data.msg);
							self.switchGroup(index);
						} else {
							self.$message.error(e.data.msg);
						}
					}).catch(e => {
						self.moveLoading = false;
					});
				}).catch(() => {});
			},

			showAddGroup(index) {
				if (index > -1) {
					this.groupForm.group_id = this.groupItem[index].group_id;
					this.groupForm.group_name = this.groupItem[index].group_name;
				} else {
					this.groupForm.group_id = null;
					this.groupForm.group_name = '';
				}
				this.groupForm.edit_index = index;
				this.addGroupVisible = true;
			},

			handleComplete(files) {
				this.uploading = false;
				if (this.simple) {
					let urls = [];
					let attachments = [];
					for (let i in files) {
						if (files[i].response.data && files[i].response.data.code === 1) {
							urls.push(files[i].response.data.data.f);
							attachments.push(files[i].response.data.data);
						}
					}
					if (!urls.length) {
						return;
					}
					this.dialogVisible = false;
					this.$emit('selected', attachments, this.params);
					if (this.multiple) {
						this.$emit('input', urls);
					} else {
						this.$emit('input', urls[0]);
					}
				}
			},

			handleSuccess(file) {
				if (file.response && file.response.data && file.response.data.code === 1) {
					const newItem = {
						checked: false,
						selected: false,
						create_time: file.response.data.data.create_time,
						extension: file.response.data.data.extension,
						file_id: file.response.data.data.file_id,
						name: file.response.data.data.name,
						file_name: file.response.data.data.file_name,
						file_path: file.response.data.data.file_path,
						file_size: file.response.data.data.file_size,
						file_type: file.response.data.data.file_type,
						file_url: file.response.data.data.file_url,
						group_id: file.response.data.data.group_id,
						storage: file.response.data.data.storage,
					};
					this.attachments.unshift(newItem);
					this.uploadCompleteFilesNum++;
				}
			},

			loadGroups() {
				let self = this;
				self.groupListLoading = true;
				self.axios({
					token: true,
					params: {
						s: 'store/upload.Library/group',
						type: this.tabs,
					},
					method: 'get',
				}).then(res => {
					if (res.data.code === 1) {
						self.groupList = res.data.data;
						self.groupItem = res.data.data;
					} else {
						self.$message.error(e.data.msg);
					}
					self.groupListLoading = false;
				}).catch(err => {
					self.groupListLoading = false;
				})
			},
			groupFormSubmit(formName) {
				let self = this;
				this.$refs[formName].validate(valid => {
					if (valid) {
						self.groupFormLoading = true;
						self.axios({
							token: true,
							params: {
								s: 'store/upload.Library/editGroup',
							},
							data: Object.assign({}, this.groupForm, {
								group_type: this.tabs
							}),
							method: 'post',
						}).then(e => {
							self.groupFormLoading = false;
							if (e.data.code === 1) {
								self.$message.success(e.data.msg);
								self.addGroupVisible = false;
								if (self.groupForm.edit_index > -1) {
									self.groupItem[self.groupForm.edit_index] = e.data.data;
								} else {
									self.groupList.push(e.data.data);
								}
							} else {
								self.$message.error(e.data.msg);
							}
						}).catch(e => {
							self.groupFormLoading = false;
						});
					}
				})
			},
			deleteGroup(index, type) {
				let title;
				switch (type) {
					case 1:
						title = '是否确认将分组删除';
						break;
					default:
						title = '';
						break;
				}
				this.$confirm(title, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					self.axios({
						token: true,
						params: {
							s: 'store/upload.Library/deleteGroup',
							group_id: this.groupItem[index].group_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code === 1) {
							this.$message.success(res.data.msg);
							this.groupItem.splice(index, 1);
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(err => {})
				}).catch(() => {});
			},

			switchGroup(index) {
				this.attachments = [];
				this.page = 1;
				this.loading = true;
				this.currentGroupId = index > -1 ? this.groupItem[index].group_id : -1;
				this.loadList();
			},

			handleLoadMore(currentPage) {
				this.page = currentPage;
				this.loading = true;
				this.loadList();
			},

			loadList() {
				let self = this;
				this.selectAll = false;
				this.loading = true;
				this.attachments = [];
				this.axios({
					token: true,
					params: {
						s: 'store/upload.library/lists',
						group_id: this.currentGroupId,
						type: this.tabs,
						page: this.page,
						keyword: this.keyword,
					},
					method: 'get',
				}).then(e => {
					if (e.data.code === 1) {
						for (let i in e.data.data.data) {
							e.data.data.data[i].checked = false;
							e.data.data.data[i].selected = false;
							e.data.data.data[i].duration = null;
						}
						self.attachments = e.data.data.data;
						self.pagination = {
							per_page: e.data.data.per_page,
							total: e.data.data.total
						}
						self.checkedAttachments = [];
						self.loading = false;
					} else {
						this.$message.error(e.data.msg);
						this.dialogVisible = false;
					}
				}).catch(err => {

				})
			},
		}
	}
</script>

<style scoped>
	.table-body {
		padding: 20px;
		background-color: #fff;
	}

	.main-box {
		margin-bottom: 10px;
		min-height: 68vh;
		width: 100%;
	}

	.main-box .left {
		border: 1px solid #e3e3e3;
		margin-right: 15px;
		width: 250px;
		box-sizing: border-box;
	}

	.material-list {
		width: 100%;
		padding: 5px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.material-list * {
		box-sizing: border-box;
	}

	.material-list:after {
		clear: both;
		display: block;
		content: " ";
	}

	.material-item {
		display: inline-block;
		cursor: pointer;
		position: relative;
		float: left;
		width: 160px;
		height: 180px;
		margin: 7.5px;
		text-align: center;
		padding: 10px 10px 0;
	}

	.material-item.checked,
	.material-item.selected {
		box-shadow: 0 0 0 1px #1ed0ff;
		background: #daf5ff;
		border-radius: 5px;
	}

	.material-item .material-img {
		display: block;
	}

	.material-item .file-type-icon {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		background: #666;
		color: #fff;
		text-align: center;
		line-height: 30px;
		font-size: 24px;
	}

	.material-upload {
		box-shadow: none;
		border: 1px dashed #b2b6bd;
		height: 140px;
		width: 140px;
		margin: 17.5px;
		padding: 0;
	}

	.material-upload i {
		font-size: 30px;
		color: #909399;
	}

	.material-upload:hover {
		box-shadow: none;
		border: 1px dashed #409EFF;
	}

	.material-upload:hover i {
		color: #409EFF;
	}

	.material-upload:active {
		border: 1px dashed #20669c;
	}

	.material-upload:active i {
		color: #20669c;
	}

	.material-dialog .group-menu {
		border-right: none;
		width: 100%;
	}

	.material-dialog .group-menu .el-menu-item {
		padding-left: 10px !important;
		padding-right: 10px;
	}

	.material-dialog .group-menu .el-menu-item .el-button {
		padding: 3px 0;
	}

	.del-material-dialog .group-menu .el-menu-item .el-button:hover {
		background: #e2e2e2;
	}

	.material-dialog .group-menu .el-menu-item .el-button i {
		margin-right: 0;
	}

	.material-simple-upload i {
		font-size: 32px;
	}

	.material-video-cover {
		background-size: 100% 100%;
		background-position: center;
	}

	.material-dialog .material-name {
		color: #666666;
		font-size: 13px;
		margin-top: 0px;
		margin-right: auto;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.search .el-input__inner {
		border-right: 0;
	}

	.search .el-input__inner:hover {
		border: 1px solid #dcdfe6;
		border-right: 0;
		outline: 0;
	}

	.search .el-input__inner:focus {
		border: 1px solid #dcdfe6;
		border-right: 0;
		outline: 0;
	}

	.search .el-input-group__append {
		background-color: #fff;
		border-left: 0;
		width: 10%;
		padding: 0;
	}

	.search .el-input-group__append .el-button {
		padding: 0;
	}

	.search .el-input-group__append .el-button {
		margin: 0;
	}

	.right {
		width: calc(100% - 265px);
		border: 1px solid #e3e3e3;
	}

	/* https://github.com/ElemeFE/element/pull/15359 */
	.el-input .el-input__count .el-input__count-inner {
		background: #FFF;
		display: inline-block;
		padding: 0 5px;
		line-height: normal;
	}
</style>
